import {
  DELETE_PROFILE,
  LOAD,
  NEW_PROFILE,
  SELECT_PROFILE,
  SET_PIPED_INSTANCES,
  SET_YOUTUBE_RESULTS,
  UPDATE_SETTINGS,
  SET_SEARCH_TEXT,
  SET_SEARCHING,
  SET_SEARCH_TERM,
  SET_NEXT_PAGE,
  SET_LOADING_MORE,
  SELECT_VIDEO,
} from "./actions";
import { io } from "socket.io-client";
import { v4 as uuid } from "uuid";
import axios from "axios";
/**
 * Actions take place here
 * Data is synchronized across all pages and components
 */

const blankSettings = {
  temperature: 50,
  head: 1,
  position: [0, 0],
  theme: "default",
};

let initialSettings = JSON.parse(JSON.stringify(blankSettings));
let profiles = [];
let loaded = false;

if (typeof document !== "undefined") {
  const p = document.getElementById("metadata");
  if (p) {
    const metadata = JSON.parse(p.textContent);
    if (metadata.settings) initialSettings = metadata.settings;
    if (metadata.profiles) profiles = metadata.profiles;
    loaded = true;
    p.remove();
  }
}

const initialState = {
  settings: initialSettings,
  socket: io("/"),
  searchText: "",
  searchTerm: "",
  youtubeResults: false,
  searching: false,
  profiles: profiles,
  loaded: loaded,
  pipedInstances: [],
  nextPage: "",
  videoSelected: "",
  loadingMore: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        profiles: action.data.profiles,
        settings: action.data.settings,
        loaded: true,
      };
    case SELECT_VIDEO:
      return {
        ...state,
        videoSelected: action.data,
      };
    case SET_LOADING_MORE:
      return {
        ...state,
        loadingMore: action.data,
      };
    case SET_NEXT_PAGE:
      return {
        ...state,
        nextPage: action.data,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: state.searchText,
        nextPage: "",
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.data,
      };
    case SET_YOUTUBE_RESULTS:
      return {
        ...state,
        youtubeResults: action.data,
      };
    case SET_PIPED_INSTANCES:
      return {
        ...state,
        pipedInstances: action.data,
      };
    case SELECT_PROFILE:
      const settings = state.profiles.find(
        (profile) => profile._id === action.data
      );
      axios
        .post("/select-profile", settings)
        .catch((err) => console.log("select profile error", err));
      return {
        ...state,
        settings: settings,
      };
    case UPDATE_SETTINGS:
      const newSettings = {
        ...state.settings,
        ...action.data,
      };
      if (newSettings._id) state.socket.emit("settings", newSettings);
      return {
        ...state,
        settings: newSettings,
        profiles: state.profiles.map((profile) => {
          if (profile._id === state.settings._id) return newSettings;
          return profile;
        }),
      };
    case DELETE_PROFILE:
      axios
        .get("/delete/" + state.settings._id)
        .catch((err) => console.log("delete error", err));
      return {
        ...state,
        profiles: state.profiles.filter(
          (profile) => profile._id !== state.settings._id
        ),
        settings: blankSettings,
      };
    case NEW_PROFILE:
      const newProfile = {
        ...state.settings,
        name: action.data,
        _id: uuid(),
      };
      axios
        .post("/new-profile", newProfile)
        .catch((err) => console.log("Fetch error", err));
      return {
        ...state,
        settings: newProfile,
        profiles: [...profiles, newProfile],
      };
    case SET_SEARCHING:
      return {
        ...state,
        searching: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
