import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { connect } from "react-redux";
import { MDBRipple, MDBContainer } from "mdb-react-ui-kit";
import { ReactSVG } from "react-svg";
import { update_settings } from "../redux/actions";
import { Joystick } from "react-joystick-component";
import Slider from "@mui/material/Slider";
import ProfileSelector from "../components/ProfileSelector";

const Main = ({ settings, update_settings }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setHeadButton();
  }, [settings._id]);
  useEffect(() => {
    setTempColor();
  }, [settings.temperature]);
  const setTempColor = () => {
    const r = Math.round(18 + 231 * (settings.temperature / 100));
    const g = Math.round(102 - 53 * (settings.temperature / 100));
    const b = Math.round(241 - 157 * (settings.temperature / 100));
    document.getElementById(
      "temp-slider"
    ).style.color = `rgb(${r}, ${g}, ${b})`;
  };

  const setHeadButton = () => {
    let headButton =
      document.getElementById("joystick-container").childNodes[0].childNodes[0];
    headButton.textContent = "Shower Head";
    headButton.classList.add("display-4");
    headButton.classList.add("fs-4");
    setLoaded(!loaded);
  };

  const selectHead = (head) => {
    if (settings.head !== head)
      update_settings({
        head: head,
      });
  };

  const getJoystickSize = () => {
    const container = document.getElementById("joystick-container");
    if (container) return Math.round(container.clientHeight * 0.6);
    return 100;
  };

  const headMove = (e) =>
    update_settings({
      position: [e.x, e.y],
    });

  const tempChange = (e, temp) =>
    update_settings({
      temperature: temp,
    });
  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out}
      className="h-100 pt-4 bg-default"
      onAnimationComplete={() => setLoaded(!loaded)}
    >
      <MDBContainer className="h-100 d-flex flex-column">
        <div className="row">
          <div className="col-4">
            <MDBRipple
              onClick={() => selectHead(1)}
              rippleColor="#1976d2"
              className="w-50 h-100 cursor-pointer mx-auto d-block rounded-6"
            >
              <ReactSVG
                className="d-block w-100"
                src="/assets/images/high.svg"
                beforeInjection={(svg) => {
                  svg.childNodes.forEach((node) => {
                    if (node.childNodes[0]) {
                      if (settings.head === 1) {
                        node.childNodes[0].classList.add("svg-blue");
                      }
                      node.childNodes[0].classList.add("transition-50");
                    }
                  });
                  svg.classList.add("w-100");
                  svg.classList.add("h-100");
                }}
              />
            </MDBRipple>
          </div>
          <div className="col-4">
            <MDBRipple
              onClick={() => selectHead(2)}
              rippleColor="#1976d2"
              className="w-50 h-100 cursor-pointer mx-auto d-block rounded-6"
            >
              <ReactSVG
                className="d-block w-100"
                src="/assets/images/medium.svg"
                beforeInjection={(svg) => {
                  svg.childNodes.forEach((node) => {
                    if (node.childNodes[0]) {
                      if (settings.head === 2) {
                        node.childNodes[0].classList.add("svg-blue");
                      }
                      node.childNodes[0].classList.add("transition-50");
                    }
                  });
                  svg.classList.add("w-100");
                  svg.classList.add("h-100");
                }}
              />
            </MDBRipple>
          </div>
          <div className="col-4">
            <MDBRipple
              onClick={() => selectHead(3)}
              rippleColor="#1976d2"
              className="w-50 h-100 cursor-pointer mx-auto d-block rounded-6"
            >
              <ReactSVG
                className="d-block w-100"
                src="/assets/images/low.svg"
                beforeInjection={(svg) => {
                  svg.childNodes.forEach((node) => {
                    if (node.childNodes[0]) {
                      if (settings.head === 3) {
                        node.childNodes[0].classList.add("svg-blue");
                      }
                      node.childNodes[0].classList.add("transition-50");
                    }
                  });
                  svg.classList.add("w-100");
                  svg.classList.add("h-100");
                }}
              />
            </MDBRipple>
          </div>
        </div>
        <div>
          <hr />
          <h5 className="text-center display-6 mb-2">Temperature</h5>
          <Slider
            id="temp-slider"
            value={settings.temperature}
            aria-label="Temperature"
            valueLabelDisplay="off"
            onChange={tempChange}
            color="danger"
          />
        </div>
        <div
          id="joystick-container"
          className="flex-grow-1 h-0 d-flex justify-content-center align-items-center"
        >
          <Joystick
            size={getJoystickSize()}
            sticky={true}
            baseColor="#4f4f4f"
            stickColor="#90A4AE"
            move={headMove}
            pos={{
              x: settings.position[0],
              y: settings.position[1],
            }}
            key={String(settings._id)}
          />
        </div>
      </MDBContainer>
      <ProfileSelector />
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { update_settings })(Main);
