import React from "react";
import { connect } from "react-redux";
import { MDBRipple, MDBTooltip } from "mdb-react-ui-kit";
import { update_settings } from "../redux/actions";

const DarkModeToggle = ({ settings, update_settings }) => {
  const toggleDarkMode = () => {
    update_settings({
      theme: settings.theme === "default" ? "dark" : "default",
    });
  };

  return (
    <div style={{ bottom: "10px", left: "10px" }} className="position-absolute">
      <div style={{ zIndex: "100" }} className="position-relative">
        <MDBTooltip
          placement="right"
          tag="div"
          title="Toggle Dark Mode"
          wrapperProps={{ onClick: toggleDarkMode }}
        >
          <MDBRipple
            rippleColor="primary"
            className="d-block p-4 cursor-pointer mx-auto rounded-circle"
          >
            <i className="fas fa-sun fa-3x mx-auto d-block text-center"></i>
          </MDBRipple>
        </MDBTooltip>
      </div>
    </div>
  );
};

const mapStateToProps = ({ settings }) => ({ settings });

export default connect(mapStateToProps, { update_settings })(DarkModeToggle);
