import React from "react";
import YouTube from "react-youtube";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import { select_video } from "../redux/actions";

const VideoPlayer = ({ videoSelected, select_video }) => {
  const location = useLocation();
  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out}
      style={{
        backgroundColor: "rgba(110, 110, 110, 0.5)",
        zIndex: location.pathname === "/youtube" ? 1000 : -1000,
      }}
      className="position-absolute top-0 bottom-0 left-0 right-0 w-100 h-100 d-flex flex-column"
    >
      <div className="p-4">
        <MDBBtn
          onClick={() => select_video("")}
          className="text-dark"
          color="link"
          rippleColor="dark"
          size="lg"
        >
          <i className="fas fa-times" />
        </MDBBtn>
      </div>
      <YouTube
        videoId={videoSelected}
        className="flex-grow-1 h-0 w-100"
        iframeClassName="h-100 w-100"
        opts={{
          playerVars: {
            autoplay: 1,
          },
        }}
      />
    </motion.div>
  );
};

const mapStateToProps = ({ videoSelected }) => ({ videoSelected });

export default connect(mapStateToProps, { select_video })(VideoPlayer);
