import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";

const Nav = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <MDBTabs>
      <MDBTabsItem>
        <MDBTabsLink
          onClick={() => history.push("/")}
          active={location.pathname === "/"}
        >
          Shower
        </MDBTabsLink>
      </MDBTabsItem>
      <MDBTabsItem>
        <MDBTabsLink
          onClick={() => history.push("/youtube")}
          active={location.pathname === "/youtube"}
        >
          YouTube
        </MDBTabsLink>
      </MDBTabsItem>
    </MDBTabs>
  );
};

export default Nav;
