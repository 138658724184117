import React, { useState } from "react";
import { MDBSelect, MDBTooltip } from "mdb-react-ui-kit";
import { connect } from "react-redux";
import NewProfileModal from "./NewProfileModal";
import DeleteProfileModal from "./DeleteProfileModal";
import { select_profile } from "../redux/actions";

const ProfileSelector = ({ profiles, settings }) => {
  const [newProfileModal, setNewProfileModal] = useState(false);
  const [deleteProfileModal, setDeleteProfileModal] = useState(false);
  const toggleNewProfileModal = () => setNewProfileModal(!newProfileModal);
  const toggleDeleteProfileModal = () =>
    setDeleteProfileModal(!deleteProfileModal);

  const options = profiles.map((profile) => ({
    text: profile.name,
    value: profile._id,
    defaultSelected: profile._id === settings._id,
  }));
  if (options.length && !settings._id)
    options.unshift({
      text: "--- No Profile ---",
      value: "null",
      defaultSelected: true,
    });
  return (
    <>
      <NewProfileModal
        modal={newProfileModal}
        setModal={setNewProfileModal}
        toggleModal={toggleNewProfileModal}
      />
      <DeleteProfileModal
        modal={deleteProfileModal}
        setModal={setDeleteProfileModal}
        toggleModal={toggleDeleteProfileModal}
      />
      <div
        className="d-flex justify-content-end align-items-center position-absolute"
        style={{ top: "10px", right: "10px" }}
      >
        {options.length ? (
          <MDBSelect
            onValueChange={(e) => select_profile(e.value)}
            size="lg"
            data={options}
            key={settings._id}
          />
        ) : (
          <></>
        )}
        <MDBTooltip
          wrapperProps={{
            color: options.length ? "primary" : "success",
            size: "lg",
            className: "ms-2",
            onClick: toggleNewProfileModal,
          }}
          title="New Profile"
          placement="left"
          className="me-2"
        >
          <i className={`fas fa-${options.length ? "plus" : "save"}`} />
        </MDBTooltip>
        {options.length && settings._id ? (
          <MDBTooltip
            wrapperProps={{
              color: "danger",
              size: "lg",
              className: "ms-2",
              onClick: toggleDeleteProfileModal,
            }}
            title="New Profile"
            placement="left"
            className="me-2"
          >
            <i className="far fa-trash-alt" />
          </MDBTooltip>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ profiles, settings }) => ({
  profiles: profiles,
  settings: settings,
});

export default connect(mapStateToProps, { select_profile })(ProfileSelector);
