import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import t from "../utilities/transitions";
import { LinearProgress } from "@mui/material";
import {
  MDBContainer,
  MDBInputGroup,
  MDBBtn,
  MDBInput,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import Spinner from "../components/Spinner";
import {
  select_video,
  set_search_text,
  set_search_term,
} from "../redux/actions";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";

const Youtube = ({
  nextPage,
  searching,
  searchText,
  searchTerm,
  set_search_text,
  youtubeResults,
  loadingMore,
  videoSelected,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setSuggestions([]);
  }, [searching, searchTerm, videoSelected]);

  useEffect(() => {
    if (searchText && !searching) getSuggestions();
  }, [searchText]);

  const getSuggestions = () =>
    axios
      .get(
        "https://pipedapi.kavin.rocks/suggestions?query=" +
          encodeURIComponent(searchText.trim())
      )
      .then((res) => setSuggestions(res.data))
      .catch((err) => console.log("suggestion error", err));

  const search = () => {
    if (!(searching || !searchText || searchTerm === searchText))
      set_search_term(true);
  };

  const compiledNumber = (value) => {
    value = Number(value);
    if (value > 1000000000)
      return String((value / 1000000000).toFixed(1)) + "B";
    else if (value > 1000000) return String((value / 1000000).toFixed(1)) + "M";
    else if (value > 1000) return String((value / 1000).toFixed(1)) + "K";
    return value;
  };

  const getDuration = (time) => {
    let hours = 0;
    let minutes = 0;
    let seconds = time;
    while (seconds >= 60) {
      minutes++;
      seconds -= 60;
    }
    while (minutes >= 60) {
      hours++;
      minutes -= 60;
    }
    if (!hours) hours = "";
    else {
      hours = String(hours);
      if (hours.length < 2) hours = `0${hours}`;
    }
    if (!minutes) minutes = "0";
    else {
      minutes = String(minutes);
      if (minutes.length < 2) minutes = `0${minutes}`;
    }
    seconds = String(seconds);
    if (seconds.length < 2) seconds = `0${seconds}`;
    return `${hours}${hours ? ":" : ""}${minutes}:${seconds}`;
  };

  const handleScroll = (e) => {
    const scrollDifference =
      e.target.scrollHeight -
      (e.target.scrollTop +
        Math.round(
          Number(getComputedStyle(e.target).height.replace("px", ""))
        ));
    if (!loadingMore && !searching && nextPage && scrollDifference < 300)
      // loadMore();
      console.log("load more");
  };

  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out}
      onClick={() => setSuggestions([])}
      className="h-100"
    >
      {youtubeResults ? (
        <motion.div
          transition={t.transition}
          initial={t.fade_out}
          animate={t.normalize}
          exit={t.fade_out}
          className="h-100 d-flex flex-column"
        >
          <MDBContainer
            style={{ zIndex: 20 }}
            className="py-4 position-relative"
          >
            <MDBInputGroup
              id="youtube-search"
              className="bg-white search-group"
              textAfter={
                searchText && (
                  <MDBBtn
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      boxShadow: "none",
                    }}
                    color="success"
                    type="button"
                    className="h-100 py-2"
                    onClick={search}
                    disabled={
                      searching || !searchText || searchTerm === searchText
                    }
                  >
                    {searching ? (
                      <Spinner size="sm" />
                    ) : (
                      <SendIcon fontSize="small" />
                    )}
                  </MDBBtn>
                )
              }
              textBefore={
                <i
                  style={{ transition: "0.2s" }}
                  className="fas fa-search search-icon text-dark"
                ></i>
              }
            >
              <MDBInput
                label="Search Youtube"
                className="w-100"
                value={searchText}
                onChange={(e) => set_search_text(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    search();
                  }
                }}
              />
            </MDBInputGroup>
            {suggestions.length && !searching ? (
              <MDBListGroup
                style={{
                  width: document.getElementById("youtube-search").offsetWidth,
                }}
                className="position-absolute cursor-pointer"
              >
                {suggestions.map((suggestion) => (
                  <MDBListGroupItem
                    onClick={() => {
                      set_search_text(suggestion);
                      search();
                    }}
                    action
                  >
                    {suggestion}
                  </MDBListGroupItem>
                ))}
              </MDBListGroup>
            ) : (
              <></>
            )}
          </MDBContainer>
          <div
            onScroll={handleScroll}
            className="flex-grow-1 h-0 overflow-y-auto"
          >
            {youtubeResults.length ? (
              <div className="video-grid">
                {youtubeResults.map((video) => (
                  <div
                    key={video.url}
                    className="video-grid-items position-relative"
                  >
                    <a href="#" className="video-links">
                      <div className="w-100">
                        <img
                          className="video-images"
                          src={video.thumbnail}
                          alt={video.title}
                          loading="lazy"
                        />
                        <div className="video-timestamp-buffer"></div>
                      </div>
                      <div className="video-timestamp-containers">
                        <span className="video-timestamps">
                          {() => getDuration(video.duration)}
                        </span>
                        <span className="video-timestamps">
                          {() => getDuration(video.duration)}
                        </span>
                      </div>
                      <div>
                        <p className="video-titles" title={video.title}>
                          {video.title}
                        </p>
                      </div>
                    </a>
                    <div className="d-flex">
                      <a className="channel-links" href="#">
                        <img
                          src={video.uploaderAvatar}
                          loading="lazy"
                          className="channel-images"
                          width="68"
                          height="68"
                        />
                      </a>
                      <div className="flex-grow-1 ps-2">
                        <a
                          href="#"
                          className="video-secondary-links"
                          title={video.uploaderName}
                        >
                          <span>{video.uploaderName}</span>
                          {video.uploaderVerified ? (
                            <svg
                              className="svg-check"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="check"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                className=""
                                fill="currentColor"
                                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                              ></path>
                            </svg>
                          ) : (
                            <></>
                          )}
                        </a>
                        <div className="video-metrics">
                          <span>
                            <svg
                              className="svg-viewers"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="eye"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                className=""
                                fill="currentColor"
                                d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                              ></path>
                            </svg>
                            <span className="ps-1">
                              {compiledNumber(video.views)} •
                            </span>
                          </span>
                          <span className="ps-1">{video.uploadedDate}</span>
                        </div>
                      </div>
                    </div>
                    <MDBRipple
                      rippleColor="primary"
                      className="position-absolute h-100 w-100 top-0 bottom-0 left-0 right-0 cursor-pointer"
                      onClick={() =>
                        select_video(video.url.split("/watch?v=")[1])
                      }
                    />
                  </div>
                ))}
              </div>
            ) : (
              <h5 className="text-center mt-4">No Videos Found</h5>
            )}
          </div>
        </motion.div>
      ) : (
        <MDBContainer>
          <h5 className="text-center display-6 mt-4">Loading Videos</h5>
          <LinearProgress />
        </MDBContainer>
      )}
    </motion.div>
  );
};

const mapStateToProps = ({
  searching,
  searchText,
  searchTerm,
  nextPage,
  youtubeResults,
  loadingMore,
  videoSelected,
}) => ({
  searching,
  searchText,
  searchTerm,
  youtubeResults,
  nextPage,
  loadingMore,
  videoSelected,
});

export default connect(mapStateToProps, {
  select_video,
  set_search_text,
  set_search_term,
})(Youtube);
