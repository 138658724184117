import { store } from "../";

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const update_settings = (settings) => ({
  type: UPDATE_SETTINGS,
  data: settings,
});

export const NEW_PROFILE = "NEW_PROFILE";
export const new_profile = (name) =>
  store.dispatch({
    type: NEW_PROFILE,
    data: name,
  });

export const DELETE_PROFILE = "DELETE_PROFILE";
export const delete_profile = () =>
  store.dispatch({
    type: DELETE_PROFILE,
  });

export const LOAD = "LOAD";
export const load = (data) =>
  store.dispatch({
    type: LOAD,
    data: data,
  });

export const SELECT_PROFILE = "SELECT_PROFILE";

export const select_profile = (profileID) =>
  store.dispatch({
    type: SELECT_PROFILE,
    data: profileID,
  });

export const SET_PIPED_INSTANCES = "SET_PIPED_INSTANCES";
export const set_piped_instances = (instances) =>
  store.dispatch({
    type: SET_PIPED_INSTANCES,
    data: instances,
  });

export const SET_YOUTUBE_RESULTS = "SET_YOUTUBE_RESULTS";
export const set_youtube_results = (results) =>
  store.dispatch({
    type: SET_YOUTUBE_RESULTS,
    data: results,
  });

export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const set_search_text = (text) =>
  store.dispatch({
    type: SET_SEARCH_TEXT,
    data: text,
  });

export const SET_SEARCHING = "SET_SEARCHING";
export const set_searching = (option) =>
  store.dispatch({
    type: SET_SEARCHING,
    data: option,
  });
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const set_search_term = (term) =>
  store.dispatch({
    type: SET_SEARCH_TERM,
    data: term,
  });

export const SET_NEXT_PAGE = "SET_NEXT_PAGE";
export const set_next_page = (url) =>
  store.dispatch({
    type: SET_NEXT_PAGE,
    data: url,
  });

export const SET_LOADING_MORE = "SET_LOADING_MORE";
export const set_loading_more = (option) =>
  store.dispatch({
    type: SET_LOADING_MORE,
    data: option,
  });

export const SELECT_VIDEO = "SELECT_VIDEO";
export const select_video = (video) =>
  store.dispatch({
    type: SELECT_VIDEO,
    data: video,
  });
