import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import reducer from "./redux/reducer";
import { legacy_createStore as createStore } from "redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
/**
 * Provider and store allows all components in the app to have access to the same data
 * Stops us from having to "drill" data from parent components to child components
 * createStore considered "legacy" even though it was latest method less than a year ago
 */

export const store = createStore(reducer);

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_COLOR_PRIMARY,
    },
    secondary: {
      main: process.env.REACT_APP_COLOR_SECONDARY,
    },
    info: {
      main: process.env.REACT_APP_COLOR_INFO,
    },
    warning: {
      main: process.env.REACT_APP_COLOR_WARNING,
    },
    danger: {
      main: process.env.REACT_APP_COLOR_DANGER,
    },
    success: {
      main: process.env.REACT_APP_COLOR_SUCCESS,
    },
    light: {
      main: process.env.REACT_APP_COLOR_LIGHT,
    },
    dark: {
      main: process.env.REACT_APP_COLOR_DARK,
    },
  },
});
/**
 * Define html block in /public/index.html to render the app
 * In this case, an empty div with the id "root"
 */
if (typeof document !== "undefined") {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/">
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}
