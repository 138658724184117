import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import { delete_profile } from "../redux/actions";

const DeleteProfileModal = ({ modal, setModal, toggleModal, settings }) => {
  const submit = () => {
    delete_profile();
    toggleModal();
  };
  return (
    <MDBModal show={modal} setShow={setModal}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>New Profile</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={toggleModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <h5 className="text-center display-6 my-4">
              Are you sure you want to delete {settings.name}?
            </h5>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={submit} color="danger" type="submit">
              Confirm
            </MDBBtn>
            <MDBBtn className="bg-gray" onClick={toggleModal}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

const mapStateToProps = ({ settings }) => ({ settings });

export default connect(mapStateToProps, {})(DeleteProfileModal);
