import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import { new_profile } from "../redux/actions";

const NewProfileModal = ({ modal, setModal, toggleModal }) => {
  const [text, setText] = useState("");
  const submit = () => {
    if (text) {
      new_profile(text);
      document.getElementById("new-profile-form").reset();
      setText("");
      toggleModal();
    }
  };
  return (
    <MDBModal show={modal} setShow={setModal}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBValidation id="new-profile-form" type="dialog" onSubmit={submit}>
            <MDBModalHeader>
              <MDBModalTitle>New Profile</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBValidationItem
                invalid
                feedback="Please enter a name"
                className="my-4"
              >
                <MDBInput
                  size="lg"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  label="Name"
                  required
                />
              </MDBValidationItem>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="submit">Save changes</MDBBtn>
              <MDBBtn className="bg-gray" onClick={toggleModal}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBValidation>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

const mapStateToProps = () => {};

export default connect(mapStateToProps, {})(NewProfileModal);
